$.fn.extend({
    'bySelectionVisibilityControl': function (options: Record<string, any>) {
        options = $.extend({
            selectMenu: null,
            rule: null,
        }, options)
        const $selectMenu = $(this).find(options.selectMenu)

        const switchRequestContentField = function (selectedValue: string) {
            $.each(options.rule, function (key, element) {
                key === selectedValue ? element.show() : element.hide()
            })
        }

        switchRequestContentField($selectMenu.val())

        $selectMenu.on("change", function () {
            switchRequestContentField(this.value)
        })
    },
})
