$.fn.extend({
    'agreementsChecker': function (options: Record<string, any>) {
        options = $.extend({
            agreementCheckbox: null,
            submitButton: null,
        }, options)
        const $agreementsCheckboxes = $(this).find(options.agreementCheckbox)
        const $submitButton = $(this).find(options.submitButton)

        $submitButton.prop("disabled", true)

        const toggleSubmitButton = function ($checkBox: JQuery<HTMLElement>) {
            const allAgreementsChecked = $checkBox.is(":checked") &&
                $agreementsCheckboxes.filter(':checked').length === 2
            $submitButton.prop("disabled", !allAgreementsChecked)
        }

        toggleSubmitButton($agreementsCheckboxes)

        $agreementsCheckboxes.on("change", function () {
            toggleSubmitButton($(this))
        })
    },
})
